<template>
  <main-wrapper detail footer tool-background-color="#409EFF" tool-color="#FFF" class="page__detail">
    <template slot="tool">
      <el-button type="primary" class="flat ipx-0 body-1 el-button--white" @click="$router.go(-1)" v-ripple>
        <i class="el-icon-back im-1"></i>
      </el-button>
      <span class="sub-title ipx-1">{{pageMode===ADD ? '新增车辆' : '修改车辆'}}</span>
    </template>
    <div class="v-wrapper">
      <div class="v-body" ref="scrollBody" v-loading="!mountedFlag">
        <template>
          <div class="detail__main">

            <!--车辆信息-->
            <div class="card-block">
              <p class="block__title">
                车辆信息
                <el-button icon="el-icon-edit" class="action" size="mini" type="primary" v-if="vehicleReadonly" @click="vehicleModify">修改</el-button>
                <el-button class="action" size="mini" v-if="!vehicleReadonly && pageMode == MODIFY" @click="vehicleBack">取消</el-button>
                <el-button class="action" size="mini" type="primary" v-if="!vehicleReadonly && pageMode == MODIFY" @click="vehicleSave">保存</el-button>
              </p>
              <div class="block__body">
                <el-form ref="vForm" :rules="ruleVehicle" :model="formVehicle" label-width="80px" :class="{'el-form--readonly': vehicleReadonly}">
                  <el-form-item label="所属用户" prop="HoldName">
                    <span v-if="vehicleReadonly" class="content--readonly">{{formVehicle.HoldName}}</span>
                    <el-input v-else v-model="formVehicle.HoldName" class="v-input" placeholder="请选择所属用户" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="车牌号码" prop="VehicleName">
                    <span v-if="vehicleReadonly" class="content--readonly">{{formVehicle.VehicleName}}</span>
                    <el-input v-else v-model="formVehicle.VehicleName" class="v-input" maxlength="25"></el-input>
                  </el-form-item>
                  <el-form-item label="车辆品牌" prop="VehicleBrandID">
                    <select-brand
                      use-index
                      :disabled="vehicleReadonly"
                      title=""
                      v-model="formVehicle.VehicleBrandID"
                      :options="brandList"
                      :props="brandProps"
                      placement="bottom-start"></select-brand>
                  </el-form-item>
                  <el-form-item label="车辆类型" prop="VehicleTypeID">
                    <select-brand
                      :disabled="vehicleReadonly"
                      title=""
                      v-model="formVehicle.VehicleTypeID"
                      :options="carTypeList"
                      :props="carTypeProps"
                      folder="car-type"
                      extension="gif"
                      placement="bottom-start"></select-brand>
                  </el-form-item>
                  <el-form-item label="备注">
                    <span v-if="vehicleReadonly" class="content--readonly">{{formVehicle.Remark|emptyFormat}}</span>
                    <el-input v-else type="textarea" v-model="formVehicle.Remark" maxlength="125"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>

            <!--车主信息-->
            <div class="card-block">
              <p class="block__title">
                车主信息
                <el-button icon="el-icon-edit" class="action" size="mini" type="primary" v-if="peopleReadonly" @click="peopleModify">修改</el-button>
                <el-button class="action" size="mini" v-if="!peopleReadonly && pageMode == MODIFY" @click="peopleBack">取消</el-button>
                <el-button class="action" size="mini" type="primary" v-if="!peopleReadonly && pageMode == MODIFY" @click="peopleSave">保存</el-button>
              </p>
              <div class="block__body">
                <el-form ref="pForm" :rules="rulePeople" :model="formPeople" label-width="80px" :class="{'el-form--readonly': peopleReadonly}">
                  <el-form-item label="姓名" prop="PeopleName">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.PeopleName|emptyFormat}}</span>
                    <el-input v-else v-model="formPeople.PeopleName" class="v-input" maxlength="25"></el-input>
                  </el-form-item>
                  <el-form-item label="性别" prop="PeopleSex">
                    <span v-if="peopleReadonly" class="content--readonly">{{!formPeople.PeopleName ? '--' : (formPeople.PeopleSex===2?'男':(formPeople.PeopleSex===1?'女':'--'))}}</span>
                    <el-radio-group v-else v-model="formPeople.PeopleSex">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="身份证号">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.IDCard|emptyFormat}}</span>
                    <el-input v-else v-model="formPeople.IDCard" class="v-input" maxlength="18"></el-input>
                  </el-form-item>
                  <el-form-item label="电话" prop="Mobile">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.Mobile|emptyFormat}}</span>
                    <el-input v-else v-model="formPeople.Mobile" class="v-input" maxlength="25"></el-input>
                  </el-form-item>
                  <el-form-item label="QQ">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.QQ|emptyFormat}}</span>
                    <el-input v-else v-model="formPeople.QQ" class="v-input" maxlength="11"></el-input>
                  </el-form-item>
                  <el-form-item label="微信">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.Wechat|emptyFormat}}</span>
                    <el-input v-else v-model="formPeople.Wechat" class="v-input" maxlength="25"></el-input>
                  </el-form-item>
                  <el-form-item label="家庭地址">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.HomeAddress|emptyFormat}}</span>
                    <el-input v-else v-model="formPeople.HomeAddress" readonly>
                      <el-button slot="append" icon="el-icon-search" @click="getAddressHome"></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="公司地址">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.CompanyAddress|emptyFormat}}</span>
                    <el-input v-else v-model="formPeople.CompanyAddress" readonly>
                      <el-button slot="append" icon="el-icon-search" @click="getAddressCom"></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="备注">
                    <span v-if="peopleReadonly" class="content--readonly">{{formPeople.PeopleRemark|emptyFormat}}</span>
                    <el-input v-else type="textarea" v-model="formPeople.PeopleRemark" maxlength="125"></el-input>
                  </el-form-item>
                </el-form>

              </div>
            </div>

            <!--设备信息-->
            <div class="card-block">
              <p class="block__title">
                设备信息
                <tool-tip content="录入设备信息并绑定" :hide-after="1000">
                  <el-button icon="el-icon-plus" class="action" size="mini" type="primary" @click="addDeviceBtn">增加</el-button>
                </tool-tip>
                <tool-tip content="查找并绑定已经录入的设备信息" :hide-after="1000">
                  <el-button icon="el-icon-search" class="action" size="mini" @click="openSelectDevice">查找</el-button>
                </tool-tip>
              </p>
              <div class="block__body" style="padding:0;padding-bottom: 150px;">
                <el-table ref="deviceTable" class="v-table--custom" :data="formDevice" size="mini" :show-header="!!formDevice.length">
                  <el-table-column prop="SIM2" label="设备ID" width="120" show-overflow-tooltip>
                    <template slot-scope="{row}">
                      <span class="i-link" @click="modifyDevice(row)">{{row.SIM2}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="SIM" label="SIM卡号" width="120" show-overflow-tooltip></el-table-column>
                  <el-table-column label="设备型号" width="100" show-overflow-tooltip>
                    <template slot-scope="{row}">{{getObjectTypeName(row.ObjectType)}}</template>
                  </el-table-column>
                  <el-table-column prop="HasLine" label="接线类型" width="90" show-overflow-tooltip>
                    <template slot-scope="{row}">{{row.HasLine?'有线':'无线'}}</template>
                  </el-table-column>
                  <el-table-column prop="Remark" label="备注" width="70" show-overflow-tooltip></el-table-column>
                  <el-table-column label="" show-overflow-tooltip>
                    <template slot-scope="{row}">
                      <span v-if="!row.InstallPeopleID" class="i-link" @click="gotoInstlll(row)">安装</span>
                      <span v-if="row.InstallPeopleID" class="i-link" @click="removeDevice(row)">拆除</span>
                      <span class="i-link iml-0" @click="deleteDevice(row)">解绑</span>
                      <span v-if="row.InstallPeopleID" class="iml-0" @click="gotoInstlll(row)" style="cursor:pointer;">查看安装信息</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

          </div>
        </template>

        <template>
          <!--车辆信息修改，增加-->
          <el-dialog
            append-to-body
            :visible.sync="dialogVisible"
            width="30%"
            @closed="() => $refs.dForm.resetFields()"
            >

            <el-form ref="dForm" :rules="ruleDevice" :model="device" label-width="80px">
              <el-form-item label="设备ID" prop="SIM2">
                <el-input v-model="device.SIM2" class="v-input" placeholder="请输入设备ID" v-if="device.newLine"></el-input>
                <tool-tip content="如需修改，请删除后重新添加" v-else>
                  <el-input v-model="device.SIM2" class="v-input" placeholder="请输入设备ID" :disabled="!device.newLine"></el-input>
                </tool-tip>
              </el-form-item>
              <el-form-item label="SIM卡号" prop="SIM">
                <el-input v-model="device.SIM" class="v-input" placeholder="请输入SIM卡号"></el-input>
              </el-form-item>
              <el-form-item label="设备型号" prop="ObjectType">
                <el-select v-model="device.ObjectType" placeholder="请选择设备型号">
                  <el-option v-for="item in objectTypeList" :key="item.ItemID" :label="item.ItemName" :value="item.ItemID" @click.native="() => device.ObjectTypeName = item.ItemName">
                    <span style="float: left">{{ item.ItemName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 20px;">{{ item.Remark }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接线类型" prop="HasLine">
                <el-radio-group v-model="device.HasLine">
                  <el-radio :label="true">有线</el-radio>
                  <el-radio :label="false">无线</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="device.Remark"></el-input>
              </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addDevice">确 定</el-button>
            </span>
          </el-dialog>
        </template>

        <map-select ref="map"></map-select>

        <el-dialog :title="`绑定设备: ${this.formVehicle.VehicleName||''}`" :visible.sync="dialogBind" width="70%" :append-to-body="true"
          :close-on-click-modal="false">
          <device-bind v-model="dialogBindList" v-if="dialogBindShow"></device-bind>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogBind = false">取 消</el-button>
            <el-button type="primary" @click="confirmSelectDevice">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>

    <template slot="footer" v-if="pageMode==ADD">
      <el-button type="primary" @click="okHandle">保存</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </template>
    <template slot="footer" v-else-if="pageMode==MODIFY">
      <el-button type="primary" v-if="btnSaveInstallVisible" @click="saveInstallInfo">保存安装信息</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </template>
  </main-wrapper>
</template>

<script>
import * as API from '@/api/vehicle'
import mixin from './mixin-detail'
import TreeUser from '@/views/common/tree-user'
import { QueryBrandGroup, QueryCarType, QueryDict } from '@/api/common'
import SelectBrand from '@/views/common/select-brand'
import { Modify as ModifyPeopleAPI, Add as AddPeopleAPI } from '@/api/customer'
import { Modify as ModifyDeviceAPI, Add as AddDeviceAPI, UnBind as DeleteDeviceAPI, Bind as BindAPI } from '@/api/device'
import MapSelect from '@/views/common/map-select'
import { setTimeout } from 'timers'
import DeviceBind from './device-bind'
import {RemoveDevice} from '@/api/device'
import {replaceFromArray} from '@/common/utils'
import {ModifyVehicleInstallDetailOrder} from '@/api/task'

const ADD = 'ADD'
const MODIFY = 'MODIFY'
export default {
  name: 'detail-vehicle',
  props: {
    itemId: {
      type: String,
      default: null
    }
  },
  components: {
    TreeUser,
    SelectBrand,
    MapSelect,
    DeviceBind
  },
  mixins: [mixin],
  data() {
    return {
      ADD,
      MODIFY,

      brandList: [],
      brandProps: {
        label: 'VehicleBrandName',
        value: 'VehicleBrandID'
      },

      carTypeList: [],
      carTypeProps: {
        label: 'ItemName',
        value: 'ItemID'
      },

      objectTypeList: [],

      formVehicle: {
        HoldID: null,
        HoldName: null,
        VehicleName: null,
        VehicleBrandID: null,
        VehicleTypeID: null,
        Remark: null
      },
      formPeople: {
        PeopleName: null,
        PeopleSex: 1,
        IDCard: null,
        Mobile: null,
        Wechat: null,
        QQ: null,
        HomeAddress: null,
        HomeLon: null,
        HomeLat: null,
        CompanyAddress: null,
        CompanyLon: null,
        CompanyLat: null,
        PeopleRemark: null
      },
      formDevice: [],

      ruleVehicle: {
        HoldName: [ {required: true,message:'请选择所属用户',trigger: 'blur'} ],
        VehicleName: [ {required: true,message:'请输入车牌号码',trigger: 'blur'} ],
        VehicleBrandID: [ {required: true,message:'请选择车辆品牌',trigger: 'change', type: 'number'} ],
        VehicleTypeID: [ {required: true,message:'请选择车辆类型',trigger: 'change',type: 'number'} ],
      },

      rulePeople: {
        PeopleName: [ {required: true,message:'请输入姓名',trigger: 'blur'} ],
        PeopleSex: [ {required: true,message:'请选择性别',trigger: 'blur'} ],
        Mobile: [ {required: true,message:'请输入电话号码',trigger: 'blur'} ]
      },

      ruleDevice: {
        SIM: [ {required: true,message:'请输入SIM卡号码',trigger: 'blur'} ],
        SIM2: [ {required: true,message:'请输入设备ID',trigger: 'blur'} ],
        ObjectType: [ {required: true,message:'请选择设备型号',trigger: 'change'} ],
        HasLine: [ {required: true,message:'请选择接线类型',trigger: 'change'} ],
      },

      dialogVisible: false,
      device: {},

      modifyVehicle: false,
      modifyPeople: false,

      dialogBind: false,
      dialogBindShow: true,
      dialogBindList: [],

      btnSaveInstallVisible: false
    }
  },
  computed: {
    pageMode() {
      return this.itemId === null ? ADD : MODIFY
    },
    vehicleReadonly() {
      return this.pageMode == MODIFY && !this.modifyVehicle
    },
    peopleReadonly() {
      return this.pageMode == MODIFY && !this.modifyPeople
    }
  },
  created() {
    QueryBrandGroup(1, 100, '', '', []).then(ret => {
      this.brandList = ret.data.dict
    })
    QueryCarType(1, 100, '', '', []).then(ret => {
      this.carTypeList = ret.data.list.slice()
    })
    QueryDict(1, 100, '', '', [], 58).then(ret => {
      this.objectTypeList = ret.data.list.slice()
    })

    this.btnSaveInstallVisible = this.$route.query.istask == '0'
  },
  beforeRouteLeave(to, from ,next) {
    this.backScroll()
    this.deliverParams(to)
    next()
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.name != 'detail-vehicle') {
        return
      }
      if (oldVal.name == 'install-detail' && newVal.meta.newDeviceInfo) {
        const newItem = newVal.meta.newDeviceInfo
        const index = this.formDevice.findIndex(k => k.ObjectID == newItem.ObjectID)
        this.formDevice.splice(index, 1, newItem)
      }
      this.restoreScroll()
    }
  },
  mounted() {
    if (this.pageMode == ADD) {
      this.mountedFlag = true
      this.formVehicle.HoldID = this.currentHoldId
      this.formVehicle.HoldName = this.currentHoldName
    } else if (this.pageMode == MODIFY) {
      this.fetchData().then(this.isScrollEnd)
    }
  },
  methods: {
    valideVehicle(formName) {
      const promise = new Promise((resolve, reject) => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
      return promise
    },
    isScrollEnd() {
      if (this.$route.query.t === 'bind') {
        // 滚动至底部
        this.$refs.scrollBody.scrollTop = this.$refs.scrollBody.scrollHeight
      }
    },

    backScroll() {
      this.back_scroll_height = this.$refs.scrollBody.scrollTop
    },

    restoreScroll() {
      if (this.back_scroll_height) {
        this.$refs.scrollBody.scrollTop = this.back_scroll_height
      }
    },

    deliverParams(to) {
      to.meta.formVehicle = this.formVehicle
      to.meta.formDevice = this.formDevice
    },

    async okHandle() {

      let valid
      try {
        valid = await this.valideVehicle('vForm')

        if (this.formPeople.PeopleName) {
          valid = valid && await this.valideVehicle('pForm')
        }

      } catch (e) {
        valid = false
      }

      if (!valid) {
        return
      }

      let items = this.formDevice.map(k => {
        return {
          ObjectID: -1,
          SIM: k.SIM,
          SIM2: k.SIM2,
          ObjectType: k.ObjectType,
          IsDelete: false,
          Remark: k.Remark,
          CreateTime: new Date(),
          CreateUserID: -1,
          HasLine: k.HasLine
        }
      })

      API.AddVehicleComplex(
        this.formVehicle,
        this.formPeople,
        items
      ).then(ret => {
          if (!ret.data.errCode) {
            this.$emit('refresh')
            this.$router.go(-1)
          } else {
            // 错误
            this.print('error', ret.data.errMsg)
          }
      }).catch(err => {
        // 错误
        this.print('error', err.message)
      })

    },
    fetchData() {
      const promise = new Promise((resolve, rejcet) => {
        API.QueryVehicleComplex(this.itemId).then(ret => {
          this.formVehicle = Object.assign({}, ret.data.vehicle, {
            HoldName: ret.data.holdInfo.Name
          })
          this.formPeople = Object.assign(this.formPeople, ret.data.people, {
            PeopleRemark: ret.data.people ? ret.data.people.Remark : ''
          })
          this.formDevice = ret.data.devices
          this.mountedFlag = true
          resolve()
        }).catch(err => {
          rejcet(err)
        })
      })
      return promise
    },
    addDeviceBtn() {
      this.device = {newLine: true, HasLine: false}
      this.dialogVisible = true
    },
    modifyDevice(device) {
      this.device = Object.assign({}, device, {newLine:false})
      this.dialogVisible = true
    },
    async addDevice() {
      let valid
      try {
        valid = await this.valideVehicle('dForm')
      } catch (e) {
        valid = false
      }

      if (!valid) {
        return
      }

      if (this.pageMode == MODIFY) {
        const item = Object.assign(this.device, {
          HoldID: this.formVehicle.HoldID,
          VehicleID: this.formVehicle.VehicleID
        })

        if (this.device.newLine) {
          // 增加
          AddDeviceAPI(item).then(ret => {
            if (ret.data.errCode) {
              this.print('error', ret.data.errMsg)
              return
            }
            const item = Object.assign({}, ret.data)
            this.formDevice.push(item)
            this.dialogVisible = false
          })
        } else {
          // 修改
          ModifyDeviceAPI(item).then(ret => {
            if (ret.data.errCode) {
              this.print('error', ret.data.errMsg)
              return
            }
            const index = this.formDevice.findIndex(k => k.ObjectID == ret.data.ObjectID)
            this.formDevice.splice(index, 1, ret.data)
            this.dialogVisible = false
          })
        }

      } else {
        const item = Object.assign({}, this.device)
        if (item.newLine) {
          this.formDevice.push(item)
        }

        this.dialogVisible = false
      }
    },
    deleteDevice(device) {
      if (this.pageMode == MODIFY) {
        DeleteDeviceAPI([device.ObjectID]).then(() => {
          const index = this.formDevice.findIndex(k => k.SIM == device.SIM)
          if (index != -1) {
            this.formDevice.splice(index, 1)
          }
        })
      } else {
        const index = this.formDevice.findIndex(k => k.SIM == device.SIM)
        if (index != -1) {
          this.formDevice.splice(index, 1)
        }
      }

    },
    getObjectTypeName(objectTypeID) {
      const index = this.objectTypeList.findIndex(k => k.ItemID == objectTypeID)
      if (index != -1) {
        return this.objectTypeList[index].ItemName
      }
      return ''
    },
    async vehicleSave() {
      try {
        await this.valideVehicle('vForm')
      } catch {
        return
      }
      API.ModifyVehicle(this.formVehicle).then(() => {
        this.bak_vehicle = Object.assign({}, this.formVehicle)
        this.modifyVehicle = false
      })
    },
    vehicleBack() {
      this.formVehicle = Object.assign({}, this.bak_vehicle)
      this.modifyVehicle = false
      this.$refs.vForm.resetFields()
    },
    vehicleModify() {
      this.bak_vehicle = Object.assign({}, this.formVehicle)
      this.modifyVehicle = true
    },
    async peopleSave() {
      if (this.formPeople.PeopleID) {
        ModifyPeopleAPI(this.formPeople).then(() => {
          this.bak_people = Object.assign({}, this.formPeople)
          this.modifyPeople = false
        })
      } else {
        const people = Object.assign(this.formPeople, {
          HoldID: this.formVehicle.HoldID,
          VehicleID: this.formVehicle.VehicleID
        })
        if (!people.PeopleName) {
          this.modifyPeople = false
        }

        try {
          await this.valideVehicle('pForm')
        } catch {
          return
        }

        AddPeopleAPI(people).then(ret => {
          this.bak_people = Object.assign({}, ret.data)
          this.modifyPeople = false
        })
      }

    },
    peopleBack() {
      this.formPeople = Object.assign({}, this.bak_people)
      this.modifyPeople = false
      this.$refs.pForm.resetFields()
    },
    peopleModify() {
      this.bak_people = Object.assign({}, this.formPeople)
      this.modifyPeople = true
    },
    getAddressHome() {
      this.$refs.map.getAddress().then(({address, lng, lat}) => {
        this.formPeople.HomeAddress = address
        this.formPeople.HomeLon = lng
        this.formPeople.HomeLat = lat
      }).catch()
    },
    getAddressCom() {
      this.$refs.map.getAddress().then(({address, lng, lat}) => {
        this.formPeople.CompanyAddress = address
        this.formPeople.CompanyLon = lng
        this.formPeople.CompanyLat = lat
      }).catch()
    },
    openSelectDevice() {
      this.dialogBindList.splice(0)
      this.dialogBindShow = false
      setTimeout(() => {
        this.dialogBindShow = true
        this.dialogBind = true
      }, 25)
    },
    confirmSelectDevice() {
      if (this.pageMode == MODIFY) {
        BindAPI(this.dialogBindList.map(k => k.ObjectID), this.formVehicle.VehicleID).then(() => {
          this.dialogBindList.forEach(j => {
            this.formDevice.push(j)
          })
          this.dialogBind = false
        })
      } else {
        this.dialogBindList.forEach(j => {
          this.formDevice.push(j)
        })
        this.dialogBind = false
      }
    },
    gotoInstlll(row) {
      if (this.pageMode === ADD) {
        this.$alert('请先保存信息')
        return
      }
      this.$router.push(this.$route.path.replace('detail', 'install') + '?id=' + row.ObjectID)
    },
    async removeDevice(row) {
      if (this.pageMode === ADD) {
        this.$alert('请先保存信息')
        return
      }
      try {
        await this.$confirm('拆除将删除安装信息, 是否拆除该设备？', '提示', {type: 'warning'})
        RemoveDevice(row.ObjectID).then(ret => {
          replaceFromArray(this.formDevice, ret.data, k => k.ObjectID === row.ObjectID)
          this.$refs.deviceTable.doLayout()
        })
      } catch(e){
        return
      }
    },
    saveInstallInfo () {
      ModifyVehicleInstallDetailOrder(this.$route.query.istaskid).then(ret => {
        if (!ret.data.errCode) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin.scss";
@import "../../styles/variables-simple.scss";
.title,.sub-title {
  color: inherit;
}
$card-width: 700px;
.v-input {
  max-width: 215px;
}
.detail__main {
  max-width: $card-width;
  margin-left: auto;
  margin-right: auto;
  .card-block {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;

    .block__title {
      line-height: 38px;
      height: 38px;
      padding: 0 16px;
      border-bottom: 1px solid #DCDFE6;
      color: #303133;
      .action {
        float: right;
        margin: 5px;
        margin-right: 0;
        border-radius: 15px;
      }
    }
    .block__body {
      padding: 12px 24px;
      .el-form--readonly {
        & ::v-deep .el-form-item {
          margin-bottom: 0;
        }
      }
      .content--readonly {
        color: #909399;
      }
    }
  }
}

@media screen and (min-width: 1272px) {
  .detail__main {
    //max-width: 1296px;
    .card-block {
      width: $card-width;
      margin: 12px 0 0 24px;
    }
  }
}

.v-wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }
}

.v-body {
  @include scroll-bar;
  height: 100%;
  overflow-y: auto;
}

.v-table--custom {
  & ::v-deep th {
    background-color: #ECEFF1;
  }
  & ::v-deep th:first-child .cell {
    padding-left: 16px;
  }
  & ::v-deep td:first-child .cell {
    padding-left: 16px;
  }
}
</style>
